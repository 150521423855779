@import 'styles-variables';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 1.6rem;
  font-weight: 700;
  color: $rradar-white;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  background: $rradar-grey;
  border: none;
  border-radius: 100%;
  outline: none;

  &:hover {
    background-color: $rradar-red;
  }
}

.info-tooltip {
  position: relative;
}
