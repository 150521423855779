@import 'styles-variables';

.companyDetailsContainer {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0.5em 0;

  > * {
    margin-right: 1em;
  }
}

.nameContainer {
  h1,
  h2,
  h3,
  h4 {
    margin-bottom: 0;
    font-weight: 600;
    letter-spacing: -1px;
    color: $rradar-accent-red;
  }

  h2 {
    margin-top: 0rem !important;
  }
}
