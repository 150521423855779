@import "styles-variables";
@import "../../../../styles-breakpoints.scss";

.formArea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: -5px 0px 33px 0px $rradar-black;

  @include breakpoint(phablet) {
    max-width: 40%;
  }
  max-width: 100%;
  padding: 9%;
  h2 {
    font-size: 280%;
    line-height: 1.1;
    margin-bottom: 1rem;
    border-left: 4px solid #cc0000;
    padding-left: 2rem;
  }
  p {
    margin: 0;
  }
}

.authForm {
  margin: 4rem auto;
  margin-top: 2.4rem;
  border: 1px solid $rradar-silver;
  border-radius: 1.2rem;
  opacity: 1;
  transition: opacity 0.2s ease-in 0s;
}

.error {
  display: inline-block;
  width: 100%;
  color: $rradar-red;
  text-align: center;
  transform: translateY(-150px);
}

.formGroup {
  margin: 1.5rem;

  input {
    position: relative;
    width: 100%;
    padding: 1rem;
    font-family: Roboto, sans-serif;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.5;
    text-transform: uppercase;
    background-color: white;
    border: 1px solid $rradar-silver;
    border-radius: 0.5rem;

    &::placeholder {
      text-transform: initial;
    }
  }
}

.message {
  display: block;
  width: 100%;
  padding: 1.2rem 3rem;
  font-size: 12px;
  color: $rradar-red;
  text-align: center;
}

.formFooter {
  flex: 1;
  padding: 1.5rem;
  // background: $rradar-grey;
  border-top: 1px solid $rradar-silver;
  border-radius: 0 0 1.2rem 1.2rem;

  button {
    width: 100%;
    margin: 0;

    &:hover {
      color: #ffffff;
      background: darken($rradar-red, 10%);
    }
  }
}

.formInformation {
  margin: 3rem 0 !important;
  font-size: 80%;
}

.formAreaDescription {
  padding-left: 2rem;
}
