@import 'styles-variables';

.editButton {
  padding: 1em 3em;
  font-family: inherit;
  color: $rradar-white;
  background-color: $rradar-black;
  border: 1px solid darken($rradar-black, 5%);
  border-radius: 3px;
  box-shadow: inset 0 1px 0 lighten($rradar-black, 20%);
  transition: background-color 200ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: lighten($rradar-black, 30%);
  }
}
