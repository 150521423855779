@import './styles-breakpoints.scss';

trix-toolbar {
  @include breakpoint(laptop) {
    white-space: nowrap;
  }
  margin-top: 2rem;
  white-space: normal;
}

trix-editor {
  @include breakpoint(laptop) {
    min-width: 40rem;
  }
  min-width: auto;
  min-height: 300px;
}

.trix-button-row {
  margin-bottom: 0.5em;
  button {
    @include breakpoint(laptop) {
      margin-top: 0;
    }
    padding: 0.7em 2em;
    margin-top: 0.2em;
    color: $rradar-accent-red;
    background: none;
    border: 1px solid $rradar-accent-red;

    &:hover {
      cursor: pointer;
    }

    &::after {
      content: attr(title);
      display: block;
      height: 0;
      overflow: hidden;
      font-weight: 700;
      visibility: hidden;
    }

    &.trix-active {
      // font-weight: 700;
      color: $rradar-white;
      background: $rradar-accent-red;
    }
  }

  [data-trix-action='redo'],
  [data-trix-action='undo'],
  [data-trix-action='increaseNestingLevel'],
  [data-trix-action='decreaseNestingLevel'],
  [data-trix-attribute='code'],
  [data-trix-attribute='strike'],
  [data-trix-attribute='heading1'],
  [data-trix-attribute='quote'],
  [data-trix-attribute='href'] {
    display: none;
  }
}

.trix-button-group--file-tools {
  display: none;
}