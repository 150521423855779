@import 'styles-variables';
@import '../../../../../../styles-breakpoints.scss';

.container {
  position: relative;
}

.error {
  @include breakpoint(phablet) {
    bottom: 80px;
  }
  position: absolute;
  bottom: 40px;
  padding: 0.8em 2em;
  color: $rradar-white;
  background-color: $rradar-accent-red;
  border-radius: 4px;

  &::after {
    content: '';
    position: absolute;
    top: -12px;
    left: 15%;
    width: 0;
    height: 0;
    border-right: 15px solid transparent;
    border-bottom: 15px solid $rradar-accent-red;
    border-left: 15px solid transparent;
  }
}

.input {
  width: 100%;
  padding: 0.5em 0;
  margin-bottom: 3em;
  font-size: 2em;
  font-weight: 600;
  letter-spacing: -2px;
  color: $rradar-grey;
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: inset 0 -5px 0 0 $rradar-grey;

  &::placeholder {
    @include breakpoint(phablet) {
      font-size: inherit;
    }
    font-size: 2rem;
    color: darken($rradar-pearl, 5%);
  }

  &:disabled,
  [disabled],
  :disabled::placeholder {
    margin-bottom: 0.5em;
    color: $rradar-pearl;
    box-shadow: inset 0 -5px 0 0 $rradar-pearl;
  }
}

.label {
  @include breakpoint(phablet) {
    font-size: 2em;
  }

  @include breakpoint(laptop) {
    font-size: 1.5em;
  }
  display: block;
  max-width: 100vw;
  font-size: 1.5em;
  color: $rradar-grey;
  word-break: break-word;
}

// .textarea {
//   width: 100%;
//   font-family: inherit;
//   font-size: 3em;
//   font-weight: 600;
//   border: none;
//   outline: none;
//   box-sizing: border-box;
// }
