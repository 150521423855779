$rradar-red: #CC0000;
$rradar-white: #FFFFFF;
$rradar-black: #2E2E2E;
$rradar-grey: #5E5E5E;
$rradar-silver: #CCCCCC;
$rradar-pearl: #F4F4F4;
$rradar-accent-turquoise: #1298A4;
$rradar-accent-red: #F15D59;
$rradar-accent-orange: #FAA339;
$rradar-accent-blue: #57C2F0;
$rradar-additional-yellow: #FCCF45;
$rradar-additional-dark-green: #0D9E8D;
$rradar-additional-purple: #9366AB;
$rradar-additional-mint: #83DB96;

$success-colour: rgba(76, 175, 80, 0.9);
$error-colour: rgba(244, 67, 54, 0.8);
$warning-colour: rgba(255, 118, 0, 0.8);

$font-headline: 'Poppins', 'Roboto', sans-serif;
$font-subheadline: 'Roboto', sans-serif;
$font-heading-alternate: 'Poppins', arial, sans-serif;
$font-family: 'Roboto',Helvetica, Arial, san-serif;
$font-size: 10px;
$headline-letter-spacing: 4px;
$font-strapline: 'Roboto', sans-serif;
$font-body: 'Roboto', sans-serif;
$font-menu: $font-body;
$font-form: $font-body;
$font-button: $font-body;
$font-weight: 300;
$primary-brand-colour: $rradar-red;
$body-font-colour: $rradar-black;
