.branding {
  a {
    display: flex;
    align-items: center;
    width: 270px;
    max-height: 75px;
  }
  img {
    width: 100%;
  }

  svg {
    width: 50%;
    margin-left: 1rem;
  }
}
