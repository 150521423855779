@mixin breakpoint($point) {
  @if $point == desktop {
    @media (min-width: 82em) {
      @content;
    }
  }

  @else if $point == laptop {
    @media (min-width: 64em) {
      @content;
    }
  }

  @else if $point == tablet {
    @media (min-width: 50em) {
      @content;
    }
  }

  @else if $point == phablet {
    @media (min-width: 37.5em) {
      @content;
    }
  }

  @else if $point == mobileonly {
    @media (max-width: 37.5em) {
      @content;
    }
  }
}

@mixin headpoint($point) {
  @if $point == large {
    @media (min-height: 690px) {
      @content;
    }
  }

  @else if $point == small {
    @media (max-height: 690px) {
      @content;
    }
  }
}
