@import '../../styles-breakpoints.scss';

.header {
  @include breakpoint(laptop) {
    width: 100%;
    padding: 4rem 0rem;
  }

  @include breakpoint(phablet) {
    width: 80%;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 99;
  width: 100%;
  max-width: 80%;
  padding: 1rem 3rem;
  margin: 0 auto;
}
