@import 'styles-variables';
/* stylelint-disable */

/* stylelint-enable */

.fade-enter {
  opacity: 0.01;
  transform: translateY(300px);
  transition: all 200ms ease-in-out;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
}

.fade-exit {
  opacity: 1;
  transform: translateY(0);
  transition: all 200ms ease-in-out;
}
.fade-exit.fade-exit-active {
  opacity: 0.01;
  transform: translateY(-300px);
}

.explode-enter ~ footer > div > div {
  clip-path: circle(25px at 96vw 93.5vh);
}

.explode-enter.explode-enter-active ~ footer > div > div {
  clip-path: circle(3000px at 96vw 93.5vh);
}

.explode-exit {
  clip-path: circle(3000px at 96vw 93.5vh);
}
.explode-exit.fade-exit-active {
  clip-path: circle(25px at 96vw 93.5vh);
}

/* stylelint-disable */

/* stylelint-enable */
