@import 'styles-variables';
@import '../../styles-breakpoints.scss';

.button {
  @include breakpoint(laptop) {
    min-width: 18rem;
  }
  display: inline-block;
  position: relative;
  padding: 1em 3em;
  margin: 1rem 0;
  font-family: inherit;
  font-weight: 800;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 0.25rem;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.35;
  }
}

.red {
  color: $rradar-white;
  background-color: $rradar-red;
  border: 2px solid darken($rradar-red, 10%);
  box-shadow: inset 0 1px 0 lighten($rradar-red, 10%);
}

.grey {
  color: $rradar-white;
  background-color: $rradar-grey;
  border: 1px solid darken($rradar-grey, 10%);
  box-shadow: inset 0 1px 0 lighten($rradar-grey, 10%);

  &:hover {
    background-color: darken($rradar-grey, 10%);
  }
}

.lightgrey {
  background-color: $rradar-silver;
  border: 1px solid darken($rradar-silver, 10%);
  box-shadow: inset 0 1px 0 $rradar-grey;

  &:hover {
    background-color: darken($rradar-silver, 5%);
  }
}

.noStyle {
  position: absolute;
  top: 2rem;
  right: 2rem;
  min-width: auto;
  color: $rradar-white;
  background-color: transparent;
  border: none;
}
