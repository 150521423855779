@import 'styles-variables';
@import '../../../../../../styles-breakpoints.scss';

.confirmation {
  @include breakpoint(laptop) {
    flex-direction: row;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 128rem;

  > div {
    padding: 0 1rem;
  }

  // > div:last-of-type {
  //   @include breakpoint(laptop) {
  //     width: 50%;
  //     padding-left: 1rem;
  //   }
  // }

  label {
    font-size: 1em;
    font-weight: 600;
    color: $rradar-silver;
  }

  > div:last-of-type {
    @include breakpoint(laptop) {
      width: 50%;
      padding-left: 2em;
    }
    color: $rradar-accent-red;
  }

  input {
    margin-bottom: 1em;
    box-shadow: inset 0 -5px 0 0 $rradar-silver;
  }
}
