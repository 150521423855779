@import "styles-variables";
@import "../../../../styles-breakpoints.scss";

.hero {
  @include breakpoint(phablet) {
    max-width: 60%;
  }
  color: $rradar-white;
  max-width: 100%;
  padding: 10%;
  text-align: left;
  background: #292929;
  background-image: url("../../../../assets/handslaptop.jpg");
  background-blend-mode: multiply;
  background-size: cover;

  h1 {
    @include breakpoint(phablet) {
      font-size: 8rem;
    }

    @include breakpoint(laptop) {
      font-size: 11rem;
      line-height: 1;
      letter-spacing: -9px;
    }

    @include breakpoint(desktop) {
      font-size: 11rem;
      line-height: 100%;
      letter-spacing: -9px;
    }

    @include headpoint(small) {
      margin-top: 0;
      line-height: 100%;
    }
    margin-top: 0;
    margin-bottom: 0;
    font-family: $font-headline;
    font-size: 6rem;
    font-weight: 600;
    letter-spacing: -4px;
    color: $rradar-red;
  }

  h2 {
    margin-top: 0;
    font-weight: 300;
    color: $rradar-grey;
  }
}

.lightweight {
  font-weight: 300 !important;
  color: $rradar-grey;
}

.FormInformation {
  @include breakpoint(laptop) {
    width: 40%;
  }

  @include breakpoint(phablet) {
    position: fixed;
    bottom: 3rem;
    left: 50%;
    width: 60%;
    padding: 0;
    transform: translateX(-50%);
  }
  padding: 1rem;
  margin: 0 auto;
  font-size: 90%;
  text-align: center;
}

.subtitle {
  @include breakpoint(phablet) {
    font-size: 250%;
  }
  margin: 0 auto;
  font-size: 100%;
  font-weight: 500;
  line-height: 1.2;
  border-left: 4px solid #cc0000;
  padding-left: 2rem;
}

.formContact {
  i,
  svg {
    margin-right: 1rem;
    color: $rradar-silver;
  }

  a:link,
  a:visited {
    text-decoration: none;
    color: #FFF;
  }

  a:hover {
    text-decoration: underline;
    color: #FFF;
  }
}
