.landing-block {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 65rem;
  min-height: 27.5rem;
  padding-right: 2.4rem;
  padding-left: 2.4rem;
  margin: 0 auto;
  line-height: 1.3;
  text-align: center;
  transform: translate(-50%, -50%);

  h1 {
    font-family: 'Roboto', sans-serif;
    font-size: 3.2rem;
    line-height: initial;
    letter-spacing: initial;
    color: #333333;
  }

  a {
    justify-content: center;
    width: 50%;
    margin: 0 auto;
    font-weight: 400;
    color: #333333;
    text-decoration: none;
  }
}
