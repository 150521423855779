@import '~alertify-css/alertify.css';
@import 'styles-variables';
@import 'styles-alertify';
@import 'styles-transitions';
@import 'styles-trix';
@import 'styles-breakpoints.scss';
@import '../node_modules/@fortawesome/fontawesome-svg-core/styles';

html {
  font-family: $font-family;
  font-size: $font-size;
  /* stylelint-disable */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: $font-weight;
  /* stylelint-enable */
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

* {
  &,
  &::before,
  &::after {
    box-sizing: inherit;
  }
}

html,
body {
  height: 100%;
  min-height: 100vh;
}

body {
  margin: 0;
  overflow-x: hidden;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.5;
  color: $body-font-colour;
  background-color: $rradar-white;
}
/* stylelint-disable */

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
/* stylelint-enable */
.tooltip {
  display: block;
  position: absolute;
  bottom: 150%;
  left: -20px;
  max-width: 100%;
  padding: 1rem 1.8em;
  color: $rradar-white;
  background: $rradar-grey;
  border-radius: 0.25em;
  opacity: 0;
  transform: rotateX(90deg);
  transform: scale(1);
  transition: transform 0.2s ease-in-out, opacity 0.1s ease-in 0.1s;
  transform-origin: -20px 150%;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 20px;
    border-top: 20px solid black;
    border-right: 20px solid transparent;
    border-left: 20px solid transparent;
    border-top-color: $rradar-grey;
  }
}

.show {
  opacity: 1;
  transform: rotateX(0deg);
  transform: scale(1);
}

.hidden {
  opacity: 0;
  transform: scale(0);
  transform: rotateX(-90deg);
}

.active {
  font-weight: 600;
}

.row-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  flex: 1;
}

.row {
  @include breakpoint(desktop) {
    padding: 0 0rem;
  }
  width: 100%;
  max-width: 80%;
  padding: 0 0rem;
  margin: 0 auto;
}

.buttonRow {
  @include breakpoint(phablet) {
    width: 80%;
  }

  @include breakpoint(desktop) {
    width: 100%;
  }
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 5vh;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);

  > button:last-child {
    margin-left: auto;
  }
}

.buttonRowRelative {
  position: initial !important;
  left: 0 !important;
  width: 100% !important;
  transform: none !important;
}

.columnedRow {
  display: flex;
  flex-direction: row;
}

.columnedRow33 {
  flex: 1 1 0%;
}

.columnedRow50 {
  flex: 5 1 0%;
}

.columnedRow40 {
  flex: 4 1 0%;
}

.columnedRow60 {
  flex: 6 1 0%;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #fafafa;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #e5e5e5;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaaaaa;
}

.required-asterix::after {
  content: '*';
  display: inline-block;
  position: absolute;
  top: 2rem;
  right: 0;
  font-family: inherit;
  font-size: 5rem;
  font-weight: 900;
  line-height: 1;
  color: $rradar-red;
}

.transition-group {
  position: relative;
  height: 100vh;
}

.site-wrapper {
  @include breakpoint(desktop) {
    flex-wrap: nowrap;
    width: 100%;
  }

  @include breakpoint(phablet) {
    width: 100%;
  }
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  flex: 1;
  min-height: 0;
  margin: 0 auto;

  &-home {
    flex-direction: row !important;
  }
}

.grey {
  color: $rradar-white;
  background-color: #575757;
}

.center {
  text-align: center;
}
/* stylelint-disable */

.flatpickr-calendar {
  display: none;
  position: absolute;
  width: 307.875px;
  padding: 0;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  background: transparent;
  background: $rradar-grey;
  border: 0;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  -webkit-box-shadow: 1px 0 0 #20222c, -1px 0 0 #20222c, 0 1px 0 #20222c,
    0 -1px 0 #20222c, 0 3px 13px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 0 0 #20222c, -1px 0 0 #20222c, 0 1px 0 #20222c,
    0 -1px 0 #20222c, 0 3px 13px rgba(0, 0, 0, 0.08);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  max-height: 640px;
  opacity: 1;
  visibility: visible;
}
.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}
.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}
.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}
.flatpickr-calendar.static.open {
  display: block;
  z-index: 999;
}
.flatpickr-calendar.multiMonth
  .flatpickr-days
  .dayContainer:nth-child(n + 1)
  .flatpickr-day.inRange:nth-child(7n + 7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.flatpickr-calendar.multiMonth
  .flatpickr-days
  .dayContainer:nth-child(n + 2)
  .flatpickr-day.inRange:nth-child(7n + 1) {
  -webkit-box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}
.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}
.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #20222c;
}
.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}
.flatpickr-calendar::before,
.flatpickr-calendar::after {
  content: '';
  display: block;
  position: absolute;
  left: 22px;
  width: 0;
  height: 0;
  pointer-events: none;
  border: solid transparent;
}
.flatpickr-calendar.rightMost::before,
.flatpickr-calendar.rightMost::after {
  right: 22px;
  left: auto;
}
.flatpickr-calendar::before {
  margin: 0 -5px;
  border-width: 5px;
}
.flatpickr-calendar::after {
  margin: 0 -4px;
  border-width: 4px;
}
.flatpickr-calendar.arrowTop::before,
.flatpickr-calendar.arrowTop::after {
  bottom: 100%;
}
.flatpickr-calendar.arrowTop::before {
  border-bottom-color: #20222c;
}
.flatpickr-calendar.arrowTop::after {
  border-bottom-color: #3f4458;
}
.flatpickr-calendar.arrowBottom::before,
.flatpickr-calendar.arrowBottom::after {
  top: 100%;
}
.flatpickr-calendar.arrowBottom::before {
  border-top-color: #20222c;
}
.flatpickr-calendar.arrowBottom::after {
  border-top-color: #3f4458;
}
.flatpickr-calendar:focus {
  outline: 0;
}
.flatpickr-wrapper {
  display: inline-block;
  position: relative;
}
.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.flatpickr-months .flatpickr-month {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
  height: 28px;
  overflow: hidden;
  line-height: 1;
  color: #ffffff;
  text-align: center;
  background: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  fill: #ffffff;
  -webkit-box-flex: 1;
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  position: absolute;
  top: 0;
  z-index: 3;
  height: 28px;
  padding: 10px;
  line-height: 16px;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  fill: #ffffff;
}
.flatpickr-months .flatpickr-prev-month.disabled,
.flatpickr-months .flatpickr-next-month.disabled {
  display: none;
}
.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative;
}
.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*
      /*rtl:begin:ignore */
  /*
      */
  left: 0;
  /*
      /*rtl:end:ignore */
  /*
      */
}
/*
      /*rtl:begin:ignore */
/*
      /*rtl:end:ignore */
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
      /*rtl:begin:ignore */
  /*
      */
  right: 0;
  /*
      /*rtl:end:ignore */
  /*
      */
}
/*
      /*rtl:begin:ignore */
/*
      /*rtl:end:ignore */
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #eeeeee;
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}
.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}
.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  -webkit-transition: fill 0.1s;
  transition: fill 0.1s;
  fill: inherit;
}
.numInputWrapper {
  position: relative;
  height: auto;
}
.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}
.numInputWrapper input {
  width: 100%;
}
.numInputWrapper input::-ms-clear {
  display: none;
}
.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  height: 50%;
  padding: 0 4px 0 2px;
  line-height: 50%;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.15);
  opacity: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.numInputWrapper span:hover {
  background: rgba(192, 187, 167, 0.1);
}
.numInputWrapper span:active {
  background: rgba(192, 187, 167, 0.2);
}
.numInputWrapper span::after {
  content: '';
  display: block;
  position: absolute;
}
.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}
.numInputWrapper span.arrowUp::after {
  top: 26%;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(255, 255, 255, 0.6);
  border-left: 4px solid transparent;
}
.numInputWrapper span.arrowDown {
  top: 50%;
}
.numInputWrapper span.arrowDown::after {
  top: 40%;
  border-top: 4px solid rgba(255, 255, 255, 0.6);
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.numInputWrapper span svg {
  width: inherit;
  height: auto;
}
.numInputWrapper span svg path {
  fill: rgba(255, 255, 255, 0.5);
}
.numInputWrapper:hover {
  background: rgba(192, 187, 167, 0.05);
}
.numInputWrapper:hover span {
  opacity: 1;
}
.flatpickr-current-month {
  display: inline-block;
  position: absolute;
  left: 12.5%;
  width: 75%;
  height: 28px;
  padding: 6.16px 0 0;
  font-size: 135%;
  font-weight: 300;
  line-height: inherit;
  line-height: 1;
  color: inherit;
  text-align: center;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.flatpickr-current-month span.cur-month {
  display: inline-block;
  padding: 0;
  margin-left: 0.5ch;
  font-family: inherit;
  font-weight: 700;
  color: inherit;
}
.flatpickr-current-month span.cur-month:hover {
  background: rgba(192, 187, 167, 0.05);
}
.flatpickr-current-month .numInputWrapper {
  display: inline-block;
  width: 6ch;
  width: 7ch\0;
}
.flatpickr-current-month .numInputWrapper span.arrowUp::after {
  border-bottom-color: #ffffff;
}
.flatpickr-current-month .numInputWrapper span.arrowDown::after {
  border-top-color: #ffffff;
}
.flatpickr-current-month input.cur-year {
  display: inline-block;
  height: auto;
  padding: 0 0 0 0.5ch;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: 300;
  line-height: inherit;
  color: inherit;
  vertical-align: initial;
  cursor: text;
  background: transparent;
  border: 0;
  border-radius: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}
.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(255, 255, 255, 0.5);
  pointer-events: none;
  background: transparent;
}
.flatpickr-weekdays {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  width: 100%;
  height: 28px;
  overflow: hidden;
  text-align: center;
  background: transparent;
  -webkit-box-align: center;
  -ms-flex-align: center;
}
.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
span.flatpickr-weekday {
  display: block;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 0;
  font-size: 90%;
  font-weight: bolder;
  line-height: 1;
  color: #ffffff;
  text-align: center;
  cursor: default;
  background: transparent;
  -webkit-box-flex: 1;
}
.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0;
}
.flatpickr-days {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  position: relative;
  width: 307.875px;
  overflow: hidden;
  -webkit-box-align: start;
  -ms-flex-align: start;
}
.flatpickr-days:focus {
  outline: 0;
}
.dayContainer {
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  width: 307.875px;
  max-width: 307.875px;
  min-width: 307.875px;
  padding: 0;
  text-align: left;
  outline: 0;
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-flex-pack: justify;
}
.dayContainer + .dayContainer {
  -webkit-box-shadow: -1px 0 0 #20222c;
  box-shadow: -1px 0 0 #20222c;
}
.flatpickr-day {
  display: inline-block;
  -webkit-flex-basis: 14.2857143%;
  flex-basis: 14.2857143%;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  width: 14.2857143%;
  height: 39px;
  max-width: 39px;
  margin: 0;
  font-weight: 400;
  line-height: 39px;
  color: rgba(255, 255, 255, 0.95);
  text-align: center;
  cursor: pointer;
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-flex-preferred-size: 14.2857143%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
}
.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  background: #646c8c;
  border-color: #646c8c;
  outline: 0;
}
.flatpickr-day.today {
  border-color: #eeeeee;
}
.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  color: #3f4458;
  background: #eeeeee;
  border-color: #eeeeee;
}
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  color: #ffffff;
  background: #80cbc4;
  border-color: #80cbc4;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}
.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  -webkit-box-shadow: -10px 0 0 #80cbc4;
  box-shadow: -10px 0 0 #80cbc4;
}
.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}
.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #646c8c, 5px 0 0 #646c8c;
  box-shadow: -5px 0 0 #646c8c, 5px 0 0 #646c8c;
}
.flatpickr-day.disabled,
.flatpickr-day.disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(255, 255, 255, 0.3);
  cursor: default;
  background: transparent;
  border-color: transparent;
}
.flatpickr-day.disabled,
.flatpickr-day.disabled:hover {
  color: rgba(255, 255, 255, 0.1);
  cursor: not-allowed;
}
.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #80cbc4, 5px 0 0 #80cbc4;
  box-shadow: -5px 0 0 #80cbc4, 5px 0 0 #80cbc4;
}
.flatpickr-day.hidden {
  visibility: hidden;
}
.rangeMode .flatpickr-day {
  margin-top: 1px;
}
.flatpickr-weekwrapper {
  display: inline-block;
  float: left;
}
.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  -webkit-box-shadow: 1px 0 0 #20222c;
  box-shadow: 1px 0 0 #20222c;
}
.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}
.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(255, 255, 255, 0.3);
  cursor: default;
  background: transparent;
  border: none;
}
.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.flatpickr-time {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 0;
  max-height: 40px;
  overflow: hidden;
  line-height: 40px;
  text-align: center;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.flatpickr-time::after {
  content: '';
  display: table;
  clear: both;
}
.flatpickr-time .numInputWrapper {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  float: left;
  width: 40%;
  height: 40px;
  -webkit-box-flex: 1;
}
.flatpickr-time .numInputWrapper span.arrowUp::after {
  border-bottom-color: rgba(255, 255, 255, 0.95);
}
.flatpickr-time .numInputWrapper span.arrowDown::after {
  border-top-color: rgba(255, 255, 255, 0.95);
}
.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}
.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}
.flatpickr-time input {
  position: relative;
  height: inherit;
  padding: 0;
  margin: 0;
  font-size: 14px;
  line-height: inherit;
  color: rgba(255, 255, 255, 0.95);
  text-align: center;
  background: transparent;
  border: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}
.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400;
}
.flatpickr-time input:focus {
  border: 0;
  outline: 0;
}
.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  display: inline-block;
  -webkit-align-self: center;
  align-self: center;
  float: left;
  width: 2%;
  height: inherit;
  font-weight: bold;
  line-height: inherit;
  color: rgba(255, 255, 255, 0.95);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-flex-item-align: center;
}
.flatpickr-time .flatpickr-am-pm {
  width: 18%;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  outline: 0;
}
.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #707999;
}
.flatpickr-input[readonly] {
  cursor: pointer;
}

/* stylelint-enable */

@keyframes Bounce {
  0% {
    box-shadow: 0 0 0 25px rgba(204, 204, 204, 0.2);
  }
  50% {
    box-shadow: 0 0 0 5px rgba(204, 204, 204, 0.1);
  }
  100% {
    box-shadow: 0 0 0 25px rgba(204, 204, 204, 0.2);
  }
}
